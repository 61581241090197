<template>
    <v-card flat>
        <v-card-title>
            <span class="primary--text font-weight-bold">Invite Organization Members</span>
        </v-card-title>
        <v-card-text>
            <v-radio-group :disabled="numInvitationsToSend !== 0" v-model="invitationType">
                <div v-for="mode in invitationTypes" :key="mode.type">
                    <v-radio class="mt-3 caption" color="secondary" :value="mode.value" :label="mode.type"></v-radio>
                    <span :class="invitationType === mode.value ? 'caption secondary--text' : 'caption'">{{ mode.description }}</span>
                </div>
            </v-radio-group>
            <v-form v-if="invitationType" class="mt-3" ref="form" v-model="valid">
                <h4 class="primary--text">Email addresses</h4>
                <v-textarea
                    v-model="emailsInput"
                    :rules="[rules.nonEmpty]"
                    required
                    @input="updateEmailString"
                    rows="3"
                    auto-grow
                    :disabled="numInvitationsToSend !== 0"
                >
                    <template v-slot:label>Enter user emails</template>
                </v-textarea>
            </v-form>

            <div v-if="emailsInput.length" class="mt-3 primary--text">
                <h3>
                    The following users will receive an invitation email to become
                    <span v-if="invitationType === 'orgManagers'">managers</span>
                    <span v-if="invitationType === 'orgFaculties'">faculty members</span>
                    of this organization.
                </h3>
                <div>
                    <ul v-for="(email, index) in emails" :key="index" class="mt-3">
                        <li v-if="email.status === emailValidationOutcomes.LOW_RISK_EMAIL || email.status === emailValidationOutcomes.UNKNOWN_RISK_EMAIL">
                            {{ email.email }}
                            <v-chip class="ml-1" x-small color="success"
                                ><v-icon small class="mr-1">check_circle</v-icon><span class="text-uppercase font-weight-bold">validated</span></v-chip
                            >
                        </li>
                        <li v-else-if="email.status === emailValidationOutcomes.HIGH_RISK_EMAIL">
                            {{ email.email }}
                            <v-chip class="ml-1" x-small color="error"
                                ><v-icon small class="mr-1">cancel</v-icon><span class="text-uppercase font-weight-bold">validation failed</span></v-chip
                            >
                        </li>
                        <li v-else-if="email.status === emailValidationOutcomes.UNVALIDATED_EMAIL">
                            {{ email.email }}
                            <v-chip class="ml-1" x-small color="primary"
                                ><v-icon small class="mr-1">warning</v-icon><span class="text-uppercase font-weight-bold">validation error</span></v-chip
                            >
                        </li>
                        <li v-else-if="validatingEmails === true">
                            {{ email.email }}<v-progress-circular :size="20" class="ml-1" indeterminate color="secondary" />
                        </li>
                        <li v-else>{{ email.email }}</li>
                    </ul>
                </div>
            </div>
            <InvitationErrorsDialog
                :showDialog="showInvitationErrorsDialog"
                :errorType="validationErrorType"
                :invalidEmails="invalidEmailsList"
                @proceedWithTheInvitation="inviteUsers($event.value)"
                @validateEmailsAgain="validateEmailsAndInviteUsers($event.value)"
            />
        </v-card-text>
        <v-card-actions>
            <div class="d-flex align-center justify-center flex-column" style="width:100%">
                <div>
                    <v-btn
                        :loading="numInvitationsToSend > 0 || validatingEmails"
                        class="mr-1"
                        @click="validateEmailsAndInviteUsers(true)"
                        :disabled="!valid || numInvitationsToSend !== 0 || validatingEmails"
                        color="secondary"
                    >
                        <v-icon small>add</v-icon>Invite
                    </v-btn>
                    <v-btn :disabled="inviting || validatingEmails" :to="{ name: 'home-org-users' }" color="secondary" outlined text exact>cancel</v-btn>
                </div>
                <v-alert v-if="emailsValidationError || backendError" color="error" icon="warning" class="mt-4" text>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">Email Validation Error</span>
                        User invitation has failed because one or more emails could not be validated, please try again later.
                    </div>
                </v-alert>

                <v-alert v-if="foundInvalidEmails === true" type="warning" icon="warning" class="mt-4" text>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">Invalid Emails</span>
                        One or more of the provided emails might be invalid.
                    </div>
                </v-alert>

                <v-alert v-if="error" color="error" icon="warning" class="mt-4" text>
                    <div class="d-flex flex-column">
                        <span class="font-weight-bold">Invitation Failure Error</span>
                        Failed to invite the following users
                        <ul>
                            <li v-for="(email, index) in failedInvitations" :key="index">
                                {{ email }}
                            </li>
                        </ul>
                    </div>
                </v-alert>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { splitEmails } from '@/utils'
import { emailValidation } from '@/mixins/validateEmail'
const InvitationErrorsDialog = () => import('@/components/InvitationErrorsDialog')

export default {
    mixins: [emailValidation],
    components: { InvitationErrorsDialog },
    data() {
        return {
            error: false,
            emailsInput: '',
            invitationType: '',
            rules: {
                nonEmpty: p => p.length > 0 || 'Please provide a value.'
            },
            emailsData: [],
            emailList: [],
            failedInvitations: [],
            inviting: false,
            valid: false,
            numInvitationsToSend: 0,
            invitationTypes: [
                {
                    type: 'Organization Manager',
                    description: 'Organization members can create and delete space, view all instances, and request the addition of new vendor datasets.',
                    value: 'orgManagers'
                },
                {
                    type: 'Faculty Member',
                    description:
                        'Faculty members can create and delete space, and invite colleagues or students to join a space of which the faculty is an admin.',
                    value: 'orgFaculties'
                }
            ]
        }
    },
    computed: {
        emails: function() {
            if (this.$data.validatedEmails.length) {
                return this.$data.validatedEmails
            } else if (this.$data.emailsData.length) {
                return this.$data.emailsData
            }
            return []
        },
        showInvitationErrorsDialog() {
            if (this.$data.foundInvalidEmails === true || this.$data.backendError === true || this.$data.emailsValidationError === true) {
                return true
            }
            return false
        },
        validationErrorType() {
            if (this.$data.foundInvalidEmails === true) {
                return this.$data.emailValidationErrorTypes.INVALID_EMAILS_ERROR
            } else if (this.$data.backendError === true || this.$data.emailsValidationError === true) {
                return this.$data.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
            }
            return null
        }
    },
    methods: {
        updateEmailString: function() {
            this.$data.validatedEmails = []
            const emails = splitEmails(this.$data.emailsInput)
            this.$data.emailsData = emails
            this.$data.emailList = emails.map(email => email.email)
        },
        inviteUsers: function(confirmed) {
            if (confirmed === true) {
                this.$data.error = false
                var apiURL
                if (this.$data.invitationType === 'orgManagers') {
                    apiURL = '/invitations/invite_org_manager/' + this.$route.params.oid
                } else if (this.$data.invitationType === 'orgFaculties') {
                    apiURL = '/invitations/invite_org_faculty/' + this.$route.params.oid
                }
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Inviting users...',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'info'
                })
                this.$data.inviting = true
                this.$data.numInvitationsToSend = this.$data.emailList.length
                this.$data.emailList.forEach(email => {
                    const postBody = { requestee_email: email }
                    this.$axios
                        .post(apiURL, postBody, { timeout: 300000 })
                        .then(response => {})
                        .catch(() => {
                            this.$data.failedInvitations.push(email)
                            this.$data.error = true
                        })
                        .finally(() => {
                            this.$data.numInvitationsToSend -= 1
                            if (this.$data.error === false && this.$data.numInvitationsToSend === 0) {
                                this.$data.inviting = false
                                this.$store.dispatch('showSnackBar', {
                                    snackBarText: 'Invitations have been successfully sent',
                                    snackBarTimeout: 5000,
                                    snackBarIcon: 'check_circle'
                                })
                                this.$data.emailsInput = ''
                                this.$router.push({
                                    name: 'home-org-users',
                                    params: {
                                        oid: this.$route.params.oid
                                    }
                                })
                            }
                        })
                })
            }
        },
        validateEmailsAndInviteUsers: function(confirmed) {
            if (confirmed === true) {
                this.validateEmails(this.$data.emailList).finally(() => {
                    if (this.$data.emailsValidationError === false && this.$data.backendError === false && this.$data.foundInvalidEmails === false) {
                        this.inviteUsers(true)
                    }
                })
            }
        }
    }
}
</script>
